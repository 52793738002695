import React from "react"
import { ThemeContext } from "../provider.js"
import ThemeToggle from "../components/theme-toggle"
import Navigation from "../components/navigation"
import Footer from "../components/footer"

export default function FourOhFour() {
  return (
    <ThemeContext.Consumer>
      {context => (
        <div id="page" className={context.isDark ? "darkTheme" : ""}>
          <main>

            <header id="page-header" className="maxWidth flex">
              <ThemeToggle />
              <Navigation />
            </header>

            <div id="content" className="maxWidth">
              <h1>Page not found</h1>
              <p>Try one of the navigation links above!</p>
            </div>

            <div className="push"></div>
          </main>
          <Footer />
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
